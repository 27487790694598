import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Button, Title, Text, Box } from "../../components/Core";
import PageWrapper from "../../components/PageWrapper";
import CTA from "../../sections/common/CTA";
import CommingUp from "../../sections/common/CommingUp";
import imgS14 from "../../assets/image/jpg/lisa/thesis/magazin_cover.png";
import imgS15 from "../../assets/image/jpg/lisa/thesis/magazin_audi.png";
import imgS16 from "../../assets/image/jpg/lisa/thesis/magazin_audi2.png";
import imgS17 from "../../assets/image/jpg/lisa/ux/savory/savorycontact.png";
import imgS18 from "../../assets/image/jpg/lisa/ux/savory/design.png";
import imgS19 from "../../assets/image/jpg/lisa/ux/savory/entwicklung.png";
import imgS20 from "../../assets/image/jpg/lisa/ux/savory/thumbnail.png";
import { Link } from "react-scroll";




const WorkSingle = () => {
  return (
    <>
      <PageWrapper>
        <Section hero>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Box>
                  <Text variant="tag">Website</Text>
                  <Title className="my-4">
                    Savory Design <br /> design and advertising agency.
                  </Title>
                  <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                    website for a design and advertising agency based in Graz, AT .
                  </Text>
                </Box>
              </Col>
            </Row>

            <Box pt={["4rem", null, null, "6.25rem"]}>
              <img src={imgS19} alt="" className="img-fluid w-100" />
            </Box>
          </Container>
        </Section>

        <div className="mt-lg-3">
          <Container>
            <Row>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Goal</Text>
                <Title variant="cardBig" className="mt-3">
                  information about agency and opportunity for clients to place excact design orders.
                </Title>
              </Col>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Date</Text>
                <Title variant="cardBig" className="mt-3">
                   Jul, 2020
                </Title>
              </Col>
              <Col lg="4" className="d-flex justify-content-lg-end">
                <a href="https://savory.at" target="_blank"> 
                  <Button arrowRight color="#fff">Open website</Button>
                </a>
              </Col>
            </Row>
          </Container>
        </div>
        <Section className="mt-lg-5 pb-0">
          <Container>
            <Row>
            <Col lg="6" className="mb-5 mb-lg-0 pr-lg-4">
                <img src={imgS18} alt="" className="img-fluid w-100" />
              </Col>
              <Col lg="6" className="mb-5 pl-lg-4">
                <img src={imgS17} alt="" className="img-fluid w-100" />
              </Col>
              
            </Row>
          </Container>
        </Section>

        <Section bg="##FFFFFF" className="pt-6">
          <div className="mt-lg-4">
            <CommingUp name="Geknipst" link="/project/geknipst" />
          </div>  
        </Section>
        <Box py={4}>
          <CTA />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
