import React from "react";
import { Link } from "gatsby";
import { device } from "../../utils";

import { Text } from "../../components/Core";

import {
  Button
} from "../../components/Core";

const CommingUp = ({link, name}) => {
  return (
    <>
      <div className="text-center mt-lg-5">
        <Text variant="tag" className="mb-1" color="darkShade">
          Next project
        </Text>
        <Link to={link}>
          <Button
            arrowRight
            arrowDark
            className="border-0 bg-transparent shadow-none text-capitalize py-3"
            css={`
              font-weight: 700
              font-size: 1.5rem;
              letter-spacing: -1.2px;
              line-height: 1.375;
              @media ${device.md} {
                font-size: 2rem;
              }
          `}
          >
            {name}
          </Button>
        </Link>
      </div>
    </>
  );
};

export default CommingUp;
